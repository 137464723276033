export const LadderTapeIdMap = {
    "6355": "781",
    "8513": "782",
    "8514": "772",
    "8515": "779",
    "8516": "778",
    "8517": "769",
    "8518": "774",
    "8519": "777",
    "9045":	"9033",
    "9046":	"9034",
    "9047":	"9035",
    "9048":	"9036",
    "9049":	"9037",
    "9050":	"9038",
    "9051":	"9039",
    "9055":	"9040",
    "9052":	"9041",
    "9053":	"9042",
    "9054":	"9043"
}
